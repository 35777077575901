import logo200Image from 'assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React, { useCallback, Dimensions, useState, useEffect } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import OtpInput from 'react-otp-input';
import UserDataService from '../services/userService';
import AuthService from '../services/authService';
import LoadSpinner from 'components/Spinners/LoadSpinner';


class AuthForm extends React.Component {
  constructor(props) {
    super(props);

    this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
    this.state = {
      phoneNumber: '',
      resp: '',
      otp: '',
      loginResult : false,      
      spinner: false,
      isAdmin: false

    };
  }
  handleChange = otp => this.setState({ otp });

  onChangePhoneNumber(e) {
    this.setState({
      phoneNumber: e.phone,
    });    
  }

  onChangeResp(e) {
    this.setState({
      resp: e,
    });
  }
  
  get isLogin() {
    return (this.props.authState === STATE_LOGIN) && (localStorage.getItem('ini_auth') !== null);
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    this.setState({
      spinner: true
    })
    UserDataService.getOtp(this.state.phoneNumber)
      .then(response => {
        if (response.data.userId === null) {
          this.setState
          ({
            spinner: false
          })
          alert('The mobile number is not a valid!');
        } else {
          localStorage.setItem('ini_auth', JSON.stringify(response.data));
          this.setState({
            resp: response.data,
            spinner: false
          })
          this.props.onChangeAuthState(STATE_LOGIN);
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          spinner: false
        })
      });
  };
  handleGetOtp = event => {    
    event.preventDefault();
  };

 handleSubmit = event => {
    this.setState({
      spinner: true
    })
    event.preventDefault();
    var reqVal = JSON.parse(localStorage.getItem('ini_auth'));
    var data = {
      activationId: reqVal.activationId,
      userId: reqVal.userId,
      otp: this.state.otp,
    };
    UserDataService.verifyOtp(data)
      .then(response => {
        localStorage.setItem('user', JSON.stringify(response.data));
        this.setState({
          //isAdmin : response.data.roles.includes("Admin"),
          loginResult : response.data.userId === null ? false : true
        })
        if(response.data.userId === null){
            this.setState({
            loginResult : false,
            spinner: false
          })        
          this.props.onLogoClick(this.state.loginResult, false);
          return;
        }else{
          var authPayload = {
            "id": response.data.userId,
            "deviceCode": "WEB",
            "userName": response.data.userName,
            "activationId": response.data.activationId,
            "activationSecret": response.data.activationSecret            
            }
          AuthService.authenticate(authPayload)
          .then(response => {
            localStorage.setItem('authToken', JSON.stringify(response.data));
            this.setState({
              loginResult : true,
              isAdmin : response.data.roles.includes("Admin"),
              spinner: false
            })            
            this.props.onLogoClick(this.state.loginResult, response.data.roles.includes("Admin"));
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({
          spinner: false
        })
      });
    
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'VERIFY OTP';
    }

    if (!buttonText && this.isSignup) {
      return 'GET OTP';
    }

    return buttonText;
  }

  render() {
       const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <div className="shadow p-3 mb-5 bg-white rounded">
        <Form onSubmit={this.handleSubmit} style={{ height: 350 }}>
          {showLogo && (
            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: 100, height: 100 }}
                alt="logo"
                // onClick={onLogoClick}
              />
            </div>
          )}

          {this.isSignup && (
            <div>
              <FormGroup>
                <p className="font-weight-bold">Admin Phone Number</p>
              </FormGroup>
              <LoadSpinner loading={this.state.spinner} />
              <PhoneInput
                disableCountryCode={true}
                inputStyle={{ height: '50px', width: '100%', color: 'green' }}
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
                onlyCountries={['in']}
                country={'in'}
                placeholder={'Enter 10 digit phone number'}
                onChange={phone => this.onChangePhoneNumber({ phone })}
              />
            </div>
          )}

          {this.isLogin && (
            <div>
              <FormGroup>
                <p className="font-weight-bold">Enter OTP</p>
              </FormGroup>
              <LoadSpinner loading={this.state.spinner} />
              <OtpInput
                containerStyle={{
                  width: '100%',
                  justifyContent: 'center',
                }}
                isInputNum={'true'}
                inputStyle={{ height: '50px', width: '50px', margin: '8px' }}
                value={this.state.otp}
                onChange={this.handleChange}
                numInputs={4}
                separator={<span> </span>}
              />
            </div>
          )}

          <hr />

          <div className="text-center pt-1">
            <h6>
              {this.isSignup ? (
                <Button
                  size="lg"
                  disabled={this.state.spinner}
                  className="bg-gradient-theme-left border-0"
                  block
                  onClick={this.changeAuthState(STATE_LOGIN)}
                >
                  {this.renderButtonText()}
                </Button>
              ) : (
                <Button
                  size="lg"
                  disabled={this.state.spinner}
                  className="bg-gradient-theme-left border-0"
                  block
                  onClick={this.handleSubmit}
                >
                  {this.renderButtonText()}
                </Button>
              )}
            </h6>
          </div>

          {children}
        </Form>
      </div>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  resp: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
  loginResult: PropTypes.bool
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  loginResult: false,
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
};

export default AuthForm;
