import axios from 'axios';
var basePath = process.env.REACT_APP_API_BASE;

function getToken(){
  try {
    var authToken = 'Bearer ' + JSON.parse(localStorage.getItem('authToken')).token;  
    return authToken;
  } catch (error) {
    //console.log(error);
    return false;
  }
}

const product = axios.create({
  baseURL:
    `${basePath}/api/Product/`,
  headers: {
    'authorization': getToken(),
    'Content-type': 'application/json',
  },
});

const review = axios.create({
  baseURL:
    `${basePath}/api/ProductReview/`,
  headers: {
    'authorization': getToken(),
    'Content-type': 'application/json',
  },
});

const user = axios.create({
  baseURL:
    `${basePath}/api/User/`,
  headers: {
    'authorization': getToken(),
    'Content-type': 'application/json',
  },
});

const location = axios.create({
  baseURL:
    `${basePath}/api/Location`,
  headers: {
    'authorization': getToken(),
    'Content-type': 'application/json',
  },
});

const order = axios.create({
  baseURL:
    `${basePath}/api/Purchase`,
  headers: {
    'authorization': getToken(),
    'Content-type': 'application/json',
  },
});

const file = axios.create({
  baseURL:
    `${basePath}/api/File`,
  headers: {
    'authorization': getToken(),
    'Content-type': 'multipart/form-data',
  },
});

const reward = axios.create({
  baseURL:
    `${basePath}/api/Rewards`,
  headers: {
    'authorization': getToken(),
    'Content-type': 'application/json',
  },
});

const auth = axios.create({
  baseURL:
    `${basePath}/api/Authentication`,
  headers: {
    'authorization': getToken(),
    'Content-type': 'application/json',
  },
});

export { location, product, user, order, file, reward, review, auth }