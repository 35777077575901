const user = require('../http-common').user;

class UserDataService {
  getAll() {
    return user.get('/GetAll');
  }

  activate(id){
    return user.put('/Activate/' + id);
  }

  deactivate(id){
    return user.put('/Deactivate/' + id);
  }
 
  getOtp(phno) {
    return user.get(`/GetOTP/${phno}`);
  }

  verifyOtp(data) {
    return user.post(`/VerifyOTP`, data);
  }

}

export default new UserDataService();
