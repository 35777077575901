import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React, {Suspense} from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import PrivateRoute from 'components/PrivateRoute'
import { UserProvider } from './components/userContext';

const AlertPage = React.lazy(() => import('pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
const BadgePage = React.lazy(() => import('pages/BadgePage'));
const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
const CardPage = React.lazy(() => import('pages/CardPage'));
const ChartPage = React.lazy(() => import('pages/ChartPage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
const FormPage = React.lazy(() => import('pages/FormPage'));
const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
const ModalPage = React.lazy(() => import('pages/ModalPage'));
const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
const TablePage = React.lazy(() => import('pages/TablePage'));
const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('pages/WidgetPage'));
const TestApiPage = React.lazy(() => import('pages/TestApiPage'));
const UserListPage = React.lazy(() => import('pages/user/userListPage'));
const OrderListPage = React.lazy(() => import('pages/order/orderListPage'));
const RewardPage = React.lazy(() => import('pages/rewards/rewardPage'));

const ProductListPage = React.lazy(() =>
  import('pages/product/ProductListPage'),
);
const LocationListPage = React.lazy(() =>
  import('pages/location/LocationListPage'),
);
const CategoryListPage = React.lazy(() =>
  import('pages/category/categoryListPage'),
);

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      name: 'React',
      isUserAuthenticated: true
    };
  }

  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <UserProvider value={JSON.parse(localStorage.getItem('user'))}>
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                return this.state.isUserAuthenticated ? (
                  <Redirect to="/signup/" />
                ) : (
                  <Redirect to="/login/" />
                );
              }}
            />
            <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={props => <AuthPage {...props} authState={STATE_LOGIN} />}
            />
            <LayoutRoute
              exact
              path="/signup/"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_SIGNUP} />
              )}
            />

            <MainLayout breakpoint={this.props.breakpoint}>
              <Suspense fallback={<PageSpinner />}>
                <PrivateRoute path="/dashboard" isLoggedIn={this.context.value}  component={DashboardPage} />
                <Route exact path="/login-modal" component={props => <AuthModalPage />} />
                <Route exact path="/buttons" component={props => <ButtonPage />} />
                <Route exact path="/products" component={props => <ProductListPage />} />
                <Route exact path="/testApi" component={props => <TestApiPage />} />
                <Route exact path="/users" component={props => <UserListPage />} />
                <Route exact path="/locations" component={props => <LocationListPage />} />
                <Route exact path="/orders" component={props => <OrderListPage />} />
                <Route exact path="/rewards" component={props => <RewardPage />} />
                <Route exact path="/categories" component={props => <CategoryListPage />} />
                <Route exact path="/cards" component={props => <CardPage />} />
                <Route exact path="/widgets" component={props => <WidgetPage />} />
                <Route exact path="/typography" component={props => <TypographyPage />} />
                <Route exact path="/alerts" component={props => <AlertPage />} />
                <Route exact path="/tables" component={props => <TablePage />} />
                <Route exact path="/badges" component={props => <BadgePage />} />
                <Route exact path="/button-groups" component={props => <ButtonGroupPage />} />
                <Route exact path="/dropdowns" component={props => <DropdownPage />} />
                <Route exact path="/progress" component={props => <ProgressPage />} />
                <Route exact path="/modals" component={props => <ModalPage />} />
                <Route exact path="/forms" component={props => <FormPage />} />
                <Route exact path="/input-groups" component={props => <InputGroupPage />} />
                <Route exact path="/charts" component={props => <ChartPage />} />
              </Suspense>
            </MainLayout>
            <Redirect to="/signup/" />
          </Switch>
        </UserProvider>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);

//////////////////////////////////original-code////////////////////////////

// import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
// import GAListener from 'components/GAListener';
// import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
// import PageSpinner from 'components/PageSpinner';
// import AuthPage from 'pages/AuthPage';
// import React from 'react';
// import componentQueries from 'react-component-queries';
// import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
// import './styles/reduction.scss';

// const AlertPage = React.lazy(() => import('pages/AlertPage'));
// const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
// const BadgePage = React.lazy(() => import('pages/BadgePage'));
// const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
// const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
// const CardPage = React.lazy(() => import('pages/CardPage'));
// const ChartPage = React.lazy(() => import('pages/ChartPage'));
// const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
// const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
// const FormPage = React.lazy(() => import('pages/FormPage'));
// const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
// const ModalPage = React.lazy(() => import('pages/ModalPage'));
// const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
// const TablePage = React.lazy(() => import('pages/TablePage'));
// const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
// const WidgetPage = React.lazy(() => import('pages/WidgetPage'));

// const getBasename = () => {
//   return `/${process.env.PUBLIC_URL.split('/').pop()}`;
// };

// class App extends React.Component {
//   render() {
//     return (
//       <BrowserRouter basename={getBasename()}>
//         <GAListener>
//           <Switch>
//             <LayoutRoute
//               exact
//               path="/login"
//               layout={EmptyLayout}
//               component={props => (
//                 <AuthPage {...props} authState={STATE_LOGIN} />
//               )}
//             />
//             <LayoutRoute
//               exact
//               path="/signup"
//               layout={EmptyLayout}
//               component={props => (
//                 <AuthPage {...props} authState={STATE_SIGNUP} />
//               )}
//             />

//             <MainLayout breakpoint={this.props.breakpoint}>
//               <React.Suspense fallback={<PageSpinner />}>
//                 <Route exact path="/" component={DashboardPage} />
//                 <Route exact path="/login-modal" component={AuthModalPage} />
//                 <Route exact path="/buttons" component={ButtonPage} />
//                 <Route exact path="/cards" component={CardPage} />
//                 <Route exact path="/widgets" component={WidgetPage} />
//                 <Route exact path="/typography" component={TypographyPage} />
//                 <Route exact path="/alerts" component={AlertPage} />
//                 <Route exact path="/tables" component={TablePage} />
//                 <Route exact path="/badges" component={BadgePage} />
//                 <Route
//                   exact
//                   path="/button-groups"
//                   component={ButtonGroupPage}
//                 />
//                 <Route exact path="/dropdowns" component={DropdownPage} />
//                 <Route exact path="/progress" component={ProgressPage} />
//                 <Route exact path="/modals" component={ModalPage} />
//                 <Route exact path="/forms" component={FormPage} />
//                 <Route exact path="/input-groups" component={InputGroupPage} />
//                 <Route exact path="/charts" component={ChartPage} />
//               </React.Suspense>
//             </MainLayout>
//             <Redirect to="/" />
//           </Switch>
//         </GAListener>
//       </BrowserRouter>
//     );
//   }
// }

// const query = ({ width }) => {
//   if (width < 575) {
//     return { breakpoint: 'xs' };
//   }

//   if (576 < width && width < 767) {
//     return { breakpoint: 'sm' };
//   }

//   if (768 < width && width < 991) {
//     return { breakpoint: 'md' };
//   }

//   if (992 < width && width < 1199) {
//     return { breakpoint: 'lg' };
//   }

//   if (width > 1200) {
//     return { breakpoint: 'xl' };
//   }

//   return { breakpoint: 'xs' };
// };

// export default componentQueries(query)(App);
