import React  from "react";
import { Spinner } from "react-bootstrap";
import './LoadSpinner.css';

class LoadSpinner extends React.Component{
    constructor(props) {
        super(props);       
    }

    render(){
        return(    
             <div>
                { 
                    this.props.loading && 
                    <Spinner className="spinnerStyle" animation="border" variant="info">  
                        
                    </Spinner>
                }
             </div>
        )
    }
}

export default LoadSpinner;