import AuthForm, { STATE_LOGIN } from 'components/AuthForm';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';

class AuthPage extends React.Component {
  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login/');
    } else {
      this.props.history.push('/signup/');
    }
  };

  handleLogoClick = (loginResult, isAdmin) => {    
    if(loginResult && isAdmin){
      this.props.history.push('/dashboard/');
      window.location = "/dashboard/";
    }else if(loginResult && !isAdmin){
      alert("Permission Denied !");
      window.location = "/";
    }
    else{
      alert("Invalid OTP, try again!");
      window.location = "/";
    }
  };

  render() {
    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <AuthForm
              loginResult={this.props.loginResult}
              authState={this.props.authState}
              onChangeAuthState={this.handleAuthState}
              onLogoClick={this.handleLogoClick}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default AuthPage;
